import React from "react"
import styled from "@emotion/styled"
import BaseLayout from "./BaseLayout"

const BodyContInner = styled.div`
  flex: 1;
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 0;
`

const BoxedLayout = ({ children }) => {

  return (
    <BaseLayout>
      <BodyContInner>
        <main>{children}</main>
      </BodyContInner>
    </BaseLayout>
  )
}


export default BoxedLayout
