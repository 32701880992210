import React from "react"
import BoxedLayout from "../components/layouts/BoxedLayout"
import Seo from "../components/seo"

const Page = data => {
  return (
    <BoxedLayout>
      <Seo title="Upwork Sync Script" />
      <img src="https://www.upwork.com/static/marketing/ui/master/img/logo-1200x630.png" alt="upwork" />

      <h3>Hey Fellow Consultants,</h3>
      <p>
        As a solo engineer, I know how frustrating it can be to spend countless hours scrolling through Upwork listings, trying to find the perfect project to showcase your skills. That's why I created an Upwork Marketplace Script that can help make the process more efficient and effective for you.
      </p>
      <p>
        Whether you're an engineer, accountant, marketer, or in any other niche, this script can help you automate the job search process and save you time and effort. With a one-time payment of $49.99, you can run the script yourself and set your search criteria to find the perfect project. Or, if you prefer, you can opt for our a monthly payment of $9.99, and we'll run the script for you every three hours.
      </p>
      <p>
        Don't let tedious job searches hold you back from finding the perfect project to take your freelance career to the next level. With our Upwork Marketplace Script, you can take control of your job search and maximize your productivity.
      </p>
      <p>
        So why wait? Invest in yourself and try our script today!
      </p>

      <div css={{display: 'flex', justifyContent: 'space-around'}}>
        <a type="button" href="https://buy.stripe.com/6oE6oTeqN5wE7q8147" class="buttoned" target="_blank" rel="noreferrer">
          Let me own it
        </a>  
        <a href="https://buy.stripe.com/7sI00v96t8IQh0I5ko" class="buttoned" target="_blank" rel="noreferrer">
          Run this for me
        </a>
      </div>
    </BoxedLayout>
  );
}

export default Page
